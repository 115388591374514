
// Services
import ApiService from './api'

export default class TrustService {
	async get(id = null) {
		// Initialise new instance of the API service
		const apiService = new ApiService()

		if(!id) {
			return await apiService.get('trusts')
		}

		return await apiService.get(`trusts/${id}`)
	}
}
