
// Libraries
import * as React from 'react'

// Services
import UserService from '../services/user'
import TrustService from '../services/trust'

// Components
import Layout from '../components/layout'
import DuoSection from '../components/duoSection'
import Seo from '../components/seo'
import InputFactory from '../components/inputFactory'
import Button from '../components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandRock } from '@fortawesome/pro-regular-svg-icons'

// Context
import NotificationContext from '../contexts/notification'

// Initialise new instance of trust service
const trustService = new TrustService()

class LoginUpdateTrustPage extends React.Component {
	state = {
		isButtonDisabled: true,
		isButtonSuccess: false,
		user: {},
		trusts: [],
		trustId: null,
	}

	static contextType = NotificationContext

	render() {
		const { isButtonDisabled, isButtonSuccess, trusts } = this.state
		const noOptionsMessage = () => "No trusts found, amend search";

		return <Layout className="page--login-update-trust nav-blue-half">
			<Seo title="Update NHS Trust" />
			<DuoSection login admin-login>
				<div>
					<div className="content-wrap">
						<p className="color--endeavour intro"><b>Tell us a little bit more about you</b></p>
						<form onSubmit={ this.handleSubmit }>
							<legend>Help us understand where our registered carers work</legend>
							<InputFactory
								type="select"
								name="trust_id"
								onChange={ this.handleInputChange }
								options={ trusts }
								className="select-field"
								requireSearch={ true }
								requireSearchMinLength="3"
								placeholder="Enter 3 or more letters of your trust's name to search ..."
								noOptionsMessage={ noOptionsMessage }
								onKeyPress={ this.handleKeyPress }
							/>

							<p className='buttons-wrap'>
								<Button isLoading={ isButtonDisabled } isSuccess={ isButtonSuccess } type="submit" colorEndeavour xsBlock>
									<span className='default'>
										Next
									</span>
									<span className='thanks'>
										<FontAwesomeIcon icon={ faHandRock } title="Thanks icon" />
										Thank you!
									</span>
								</Button>
							</p>
						</form>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}

	async componentDidMount() {
		this.fetchTrusts();
	}

	async fetchTrusts() {
		let response = await trustService.get()
		let data = response?.data
		let trusts = []

		// Populate array with trusts
		data.map(function (trust) {
			trusts.push({
				label: trust?.name,
				value: trust?.id,
			})

			return trust
		})

		this.setState({ trusts: trusts, loading: false },)
	}

	handleInputChange = (value) => {
		const selectedTrust = value.value;
		let isButtonDisabled;
		let trustId;

		if (selectedTrust !== undefined) {
			trustId = selectedTrust;
			isButtonDisabled = false
		} else {
			trustId = null;
			isButtonDisabled = true
		}

		this.setState({
			trustId,
			isButtonDisabled
		})
	}

	handleSubmit = async (event) => {
		event.preventDefault()

		this.setState({
			isButtonDisabled: true
		})
		const userService = new UserService()
		const { trustId } = this.state
		const { addNotification } = this.context

		// append trust id to form data
		const formData = {}
		formData['trust_id'] = trustId

		await userService.updateUser(formData).then(async (response) => {
			if (response.success) {
				this.setState({
					isButtonSuccess: true
				})

				setTimeout(() => {
					this.props.navigate('/ballots')
				}, 2000)
			}
			else {
				this.setState({
					isButtonDisabled: false
				})

				addNotification(response.errors.answers, 'error')
			}
		})
			.catch(err => {
				console.error(err)

				addNotification('Trust failed to save', 'error')
			})

	}
}

export default LoginUpdateTrustPage
